<template>
  <div class="forgetWrap">
    <el-dialog
            title="忘记密码"
            width="700px"
            :visible.sync="dialogVisible"
            @close="closeInit"
            custom-class="passDia"
    >
      <div class="diaWrap">
        <div class="radioWrap">
          <el-radio-group v-model="radio" @change="changeRadio">
            <el-radio :label="1">通过联系老师重置密码</el-radio>
            <el-radio :label="2">通过短信验证修改密码</el-radio>
          </el-radio-group>
        </div>
        <div v-if="radio == 1">
          <div class="selectWrap mt20">
            <el-row gutter="10">
              <el-col :span="21">
                <el-input placeholder="请输入登录账号的唯一码" v-model="wym" class="wym">
                </el-input>
              </el-col>
              <el-col :span="3"
              ><el-button @click="getFaculty" type="primary">搜索</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="tipWrap" v-if="faculty_id == ''">
            <p class="mb10">
              1、首次登录：初始密码为本人账号后六位（字母大写）；
            </p>
            <p>2、如忘记密码，请联系学院老师重置密码。</p>
          </div>
          <div class="tableWrap mt20" v-else>
            <div class="tableHeight" v-if="teacherList.length > 0">
              <table>
                <tr>
                  <th width="50%">姓名</th>
                  <th width="50%">联系电话</th>
                </tr>
                <tr v-for="(item, index) in teacherList" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                </tr>
              </table>
            </div>
            <div class="nodataWrap" v-if="teacherList.length == 0">
              <img src="./assets/images/noData.png" alt="" />
              <p class="mt20">暂未设置联系人，请直接联系相关人员</p>
            </div>
          </div>
        </div>
        <div v-if="radio == 2">
          <div class="phoneWrap">
            <el-form ref="form" :model="form" :rules="rules" class="formBox">
              <el-form-item prop="phone">
                <el-input
                        placeholder="请输入手机号"
                        v-model="form.phone"
                        @blur="checkPhone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="captcha">
                <el-input
                        placeholder="请输入右侧验证码"
                        v-model="form.captcha"
                        class="captchaInput"
                >
                  <i slot="suffix">
                    <img
                            style="width: 80px; height: 40px; cursor: pointer"
                            :src="'/api/login/v1/foreuser/captcha?rand=' + rand"
                            @click="changeRand"
                    /> </i
                  ></el-input>
              </el-form-item>
              <el-form-item prop="smscode">
                <el-input
                        placeholder="请输入短信验证码"
                        v-model="form.smscode"
                        class="captchaInput"
                >
                  <i slot="suffix">
                    <el-button type="text" v-if="!timeFlag" @click="sendCode"
                    >发送验证码</el-button
                    >
                    <span v-else>（{{ seconds }}s）</span>
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="newpassword">
                <el-input
                        placeholder="请输入新密码"
                        v-model="form.newpassword"
                ></el-input>
                <p class="textTips" :class="wrongPassword ? 'colorRed' : ''">
                  长度为6~14个字符，字母/数字以及标点符号至少包含两种,
                  不允许有空格或汉字
                </p>
              </el-form-item>
              <el-form-item prop="relpassword">
                <el-input
                        placeholder="请确认新密码"
                        v-model="form.relpassword"
                ></el-input>
              </el-form-item>
              <div class="submitBtn">
                <el-button type="primary" round @click="submit">确定</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    toFaculty,
    toTeacher,
    toCode,
    toPassword,
    logincheckphone,
  } from "./assets/api";
  export default {
    data() {
      var vPhone = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入手机号"));
        } else {
          let a =
                  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
          if (!a.test(value)) {
            callback(new Error("手机号格式有误"));
          } else {
            callback();
          }
        }
      };
      var vNewPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入新密码"));
        } else {
          let a =
                  /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,14}$/;
          if (!a.test(value)) {
            this.wrongPassword = true;
          } else {
            this.wrongPassword = false;
            callback();
          }
        }
      };
      var vPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请确认新密码"));
        } else {
          if (value != this.form.newpassword) {
            callback(new Error("与新密码不符"));
          } else {
            callback();
          }
        }
      };
      return {
        wym: "",
        siteid: "",
        dialogVisible: false,
        radio: 1,
        facultyList: [],
        faculty_id: "",
        teacherList: [],
        rand: Date.parse(new Date()) / 1000,
        form: {
          phone: "",
          captcha: "",
          smscode: "",
          newpassword: "",
          relpassword: "",
        },
        rules: {
          phone: [{ validator: vPhone, trigger: "blur" }],
          captcha: [
            { required: true, message: "请输入右侧验证码", trigger: "blur" },
          ],
          smscode: [
            { required: true, message: "请输入短信验证码", trigger: "blur" },
          ],
          newpassword: [{ validator: vNewPassword, trigger: "blur" }],
          relpassword: [{ validator: vPassword, trigger: "blur" }],
        },
        timer: "",
        seconds: "",
        timeFlag: false,
        wrongPassword: false,
      };
    },
    mounted() {},
    methods: {
      openInit() {
        this.dialogVisible = true;
        this.radio = 1;
        this.wym = "";
        this.faculty_id = "";
        this.teacherList = [];
        this.$data.form = this.$options.data().form;
        this.wrongPassword = false;
        this.timeFlag = false;
        this.timer = "";
      },
      closeInit() {
        if (this.timer) {
          clearInterval(this.timer);
        }
      },
      //修改密码方式
      changeRadio() {
        this.wym = "";
        if (this.radio == 2) {
          this.$data.form = this.$options.data().form;
          this.timer = "";
        }
        if (this.radio == 1) {
                this.faculty_id = "";
                this.siteid = "";
            }
      },
      //获取院系
      getFaculty() {
        const params = {
          uniquecode: this.wym,
        };
        toFaculty(params).then((res) => {
          if (res.code == 0) {
            this.faculty_id = res.data.faculty_id;
            this.siteid = res.data.siteid;
            this.getTeacher();
          }
        });
      },
      //获取老师
      getTeacher() {
        const params = {
          page: 1,
          siteid: this.siteid,
          faculty_id: this.faculty_id,
        };
        toTeacher(params).then((res) => {
          if (res.code == 0) {
            this.teacherList = res.data;
          }
        });
      },
      //获取验证码
      changeRand() {
        this.rand = Date.parse(new Date()) / 1000
      },
      //验证手机号
      checkPhone() {
        if (this.form.phone) {
          const params = {
            phone: this.form.phone,
          };
          logincheckphone(params).then((res) => {
            if (res.code == 0) {
              this.facultyList = res.data;
            }
          });
        }
      },
      //发送验证码
      sendCode() {
        if (this.form.phone && this.form.captcha) {
          const params = {
            phone: this.form.phone,
            captcha: this.form.captcha,
          };
          toCode(params).then((res) => {
            if (res.code == 0) {
              this.seconds = 60;
              this.timeFlag = true;
              this.timer = setInterval(this.endTimer, 1000);
            }
          });
        } else {
          this.$message.error("请先输入手机号和验证码");
        }
      },
      endTimer() {
        this.seconds -= 1;
        if (this.seconds == 0) {
          this.timeFlag = false;
          clearInterval(this.timer);
        }
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const params = { ...this.form };
            toPassword(params).then((res) => {
              this.changeRand()
              if (res.code == 0) {
                this.$message.success(res.message);
                this.dialogVisible = false;
              }
            });
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .el-button--text{ color: #507fff !important;}
  .el-button--primary{ background: #507fff !important; border-color:#507fff !important; }
  .wym {
    /deep/.el-input__inner {
      border: 1px solid #507fff;
    }
  }
  .forgetWrap {
    /deep/.el-dialog {
      border-radius: 16px;
      .el-dialog__header {
        padding:20px;
        .el-dialog__title {
          font-weight: bold;
          font-size: 16px;
        }
        i {
          font-size: 16px;
        }
      }
    }
  }
  .diaWrap {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    .radioWrap {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      /deep/.el-radio__label {
        color: #888;
      }
      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #888;
      }
      /deep/.el-radio__input.is-checked .el-radio__inner {
        background: url("./assets/images/checkRadio.png") no-repeat center center !important;
        border: none;
      }
      /deep/.el-radio__inner::after {
        background: none;
      }
      /deep/ .el-radio__inner {
        width: 12px;
        height: 12px;
      }
    }
    .selectWrap {
      width: 100%;
      /deep/.el-select {
        width: 100%;
        .el-input__inner {
          line-height: 48px;
          border-radius: 24px;
          background: #f8f8f8;
          border: none;
          padding-left: 20px;
          &::-webkit-input-placeholder {
            color: #333;
          }
        }
      }
    }
    .tipWrap {
      margin-top: 20px;
      padding: 0 60px;
      box-sizing: border-box;
    }
    .tableWrap {
      width: 102%;
      .tableHeight {
        height: 300px;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }

        table {
          width: 100%;
          border: 1px solid #f3f3f3;
          color: #333;
          th {
            text-align: left;
            padding: 13px;
            background: #f8f8f8;
          }
          td {
            text-align: left;
            padding: 13px;
          }
          tr:not(:first-child) {
            border-bottom: 1px solid #f3f3f3;
          }
        }
      }
    }
    .nodataWrap {
      text-align: center;
      margin: 60px 0 130px;
    }
    .phoneWrap {
      width: 400px;
      margin: 0 auto;
      margin-top: 20px;
      .noPhoneWrap {
        text-align: center;
        margin: 25px auto;
        font-size: 16px;
        color: #999;
        img {
          width: 144px;
          height: 124px;
          margin-bottom: 20px;
        }
      }
      .el-input {
      }
      /deep/.el-input__inner {
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        background: #f8f8f8;
        border: none;
        font-size: 16px;
        color: #333;
        padding-left: 30px;
        padding-right: 5px;
      }
      /deep/ .el-form-item__error {
        left: 20px;
      }
      .captchaInput {
        /deep/.el-input__inner {
          padding-right: 70px;
        }
        /deep/.el-input__suffix {
          top: 5px;
          right: 24px;
        }
      }
      .submitBtn {
        text-align: center;
        margin-top: 30px;
        .el-button {
          padding: 13px 35px;
        }
      }
      .textTips {
        margin-top: 10px;
        line-height: 20px;
        padding-left: 20px;
        color: #999;
      }
      .colorRed {
        color: red;
      }
    }
  }
  .el-form-item__content {
    .zjg {
      /deep/ .el-input__suffix {
        right: 24px !important;
      }
    }
  }
</style>

