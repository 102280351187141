<template>
  <div class="settingWrap schoolUserBox">
    <el-collapse v-model="activeNames" class="schoolUser">
      <el-collapse-item name="1" :disabled="userdis">
        <template slot="title">
          <el-row style="width: 100%">
            <el-col :span="4">
              <span class="iconBk person mr10"
              ><i class="iconfont icon-person"></i></span
              ><span class="color888">账号</span>
            </el-col>
            <el-col :span="8" class="color333"
            >{{ account_name }} &nbsp;</el-col
            >
            <el-col :span="6" :offset="6" class="textAlignR" v-if="datetime">
              {{ datetime }}
            </el-col>
            <el-col :span="6" :offset="6" class="textAlignR" v-else>
              <el-button type="text" class="color888 fontSize14 mr10"
              >编辑</el-button
              >
              <i
                      class="iconfont icon-arrows fontSize18 vAlignMidd"
                      style="color: #8997b2"
              ></i>
            </el-col>
          </el-row>
        </template>
        <el-row class="schoolUserCont">
          <el-col :span="12" :offset="6" class="mb10 pl30"
          >一年内仅能修改一次，请谨慎填写</el-col
          >
          <el-col :span="12" :offset="6" class="mb10">
            <el-input
                    v-model="accountName"
                    placeholder="请输入新账号"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb15 pl30 color999"
          >账号长度为6~14个字符，支持字母、数字以及标点符号</el-col
          >
          <el-col :span="12" :offset="6">
            <el-button type="primary" class="widautoBtn" @click="editPerson"
            >确定</el-button
            >
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <el-row style="width: 100%">
            <el-col :span="4">
              <span class="iconBk bindingTel mr10"
              ><i class="iconfont icon-phone"></i></span
              ><span class="color888">绑定手机</span>
            </el-col>
            <el-col :span="8" class="color333">{{ phone }} &nbsp;</el-col>
            <el-col :span="6" :offset="6" class="textAlignR">
              <el-button type="text" class="color888 fontSize14 mr10"
              >编辑</el-button
              >
              <i
                      class="iconfont icon-arrows fontSize18 vAlignMidd"
                      style="color: #8997b2"
              ></i>
            </el-col>
          </el-row>
        </template>
        <el-row class="schoolUserCont">
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="bindingTel.phone"
                    placeholder="请输入新手机号"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="bindingTel.captcha"
                    placeholder="请输入右侧验证码"
                    class="inputRf"
            >
              <i slot="suffix" class="mr25 lineH48"
              ><img
                      style="width: 80px; height: 40px; cursor: pointer"
                      :src="'/api/login/v1/foreuser/captcha?rand=' + rand"
                      @click="changeRand"
              /></i>
            </el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="bindingTel.password"
                    placeholder="请输入验证码"
                    class="inputRf"
            >
              <span slot="suffix" class="color999 mr25 lineH48" v-show="!getyzm"
              >{{ count }}s后重新获取</span
              >
              <el-button
                      slot="suffix"
                      type="text"
                      class="mr25 lineH22"
                      @click="getCode()"
                      v-show="getyzm"
              >发送验证码</el-button
              >
            </el-input>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-button type="primary" class="widautoBtn" @click="onPhone"
            >确定</el-button
            >
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
          <el-row style="width: 100%">
            <el-col :span="4">
              <span class="iconBk editPass mr10"
              ><i class="iconfont icon-lock"></i></span
              ><span class="color888">修改密码</span>
            </el-col>
            <el-col :span="8" class="color333">{{ passwords }} &nbsp;</el-col>
            <el-col :span="6" :offset="6" class="textAlignR">
              <el-button type="text" class="color888 fontSize14 mr10"
              >编辑</el-button
              >
              <i
                      class="iconfont icon-arrows fontSize18 vAlignMidd"
                      style="color: #8997b2"
              ></i>
            </el-col>
          </el-row>
        </template>
        <el-row class="schoolUserCont">
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="password.oldpassword"
                    placeholder="请输入原密码"
                    class="inputRf"
            >
              <el-button
                      slot="suffix"
                      type="text"
                      class="mr25 lineH22"
                      @click="openForget"
              >忘记原密码？</el-button
              >
            </el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="password.newpassword"
                    placeholder="请输入新密码"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="password.relpassword"
                    placeholder="请确认新密码"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-button type="primary" class="widautoBtn" @click="setPass"
            >确定</el-button
            >
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <el-row class="notChange">
      <el-col :span="4">
        <span class="iconBk app mr10"><i class="iconfont icon-app"></i></span
        ><span class="color888">第三方应用</span>
      </el-col>
      <el-col :span="6" :offset="14" class="textAlignR">
        <el-button type="text" v-if="wxbindFlag" @click="unbindType(2)"
        ><img src="../../../../assets/images/wx.png"
        /></el-button>
        <el-button type="text" v-else @click="bingwx"
        ><img src="../../../../assets/images/notwx.png"
        /></el-button>
        <el-button type="text" v-if="qqbindFlag" @click="unbindType(1)"
        ><img src="../../../../assets/images/qq.png"
        /></el-button>
        <el-button type="text" v-else @click="bingqq"
        ><img src="../../../../assets/images/notqq.png"
        /></el-button>
      </el-col>
    </el-row>
    <el-collapse v-model="activeNames" class="schoolUser">
      <el-collapse-item name="4">
        <template slot="title">
          <el-row style="width: 100%">
            <el-col :span="4">
              <span class="iconBk bindingSchool mr10"
              ><i class="iconfont icon-school"></i></span
              ><span class="color888">绑定学校</span>
            </el-col>
            <el-col :span="6" :offset="14" class="textAlignR">
              <el-button type="text" class="color888 fontSize14 mr10"
              >绑定</el-button
              >
              <i
                      class="iconfont icon-arrows fontSize18 vAlignMidd"
                      style="color: #8997b2"
              ></i>
            </el-col>
          </el-row>
        </template>
        <el-row class="schoolUserCont">
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="school.name"
                    placeholder="请输入姓名"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-autocomplete
                    class="inputRf"
                    v-model="school.schoolname"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="请输入学校名称"
                    @select="handleSelect"
            ></el-autocomplete>
          </el-col>
          <el-col :span="12" :offset="6" class="mb24">
            <el-input
                    v-model="school.job_num"
                    placeholder="请输入学号/工号"
                    class="inputRf"
            ></el-input>
          </el-col>
          <el-col :span="12" :offset="6" class="mb10 pl20">
            <el-checkbox v-model="school.default_site" class="checkedR"
            >设为默认登录学校<span class="color999"
            >（勾选后，下次登录将默认该学习的平台）</span
            ></el-checkbox
            >
          </el-col>
          <el-col :span="12" :offset="6">
            <el-button type="primary" class="widautoBtn" @click="bindschool"
            >确定</el-button
            >
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item name="5">
        <template slot="title">
          <el-row style="width: 100%">
            <el-col :span="4">
              <span class="iconBk hat mr10"
              ><i class="iconfont icon-hat"></i></span
              ><span class="color888">选择学校</span>
            </el-col>
            <el-col :span="6" :offset="14" class="textAlignR">
              <el-button type="text" class="color888 fontSize14 mr10"
              >展开</el-button
              >
              <i
                      class="iconfont icon-arrows fontSize18 vAlignMidd"
                      style="color: #8997b2"
              ></i>
            </el-col>
          </el-row>
        </template>
        <el-row class="schoolUserCont" style="padding: 0">
          <el-col
                  :span="24"
                  class="chooseSchool"
                  v-for="(item, index) in schooList"
                  :key="index"
          >
            <el-col :span="12"
            >{{ item.name
              }}<em class="defaultSchool ml10" v-if="item.default_school == 1"
              >默认学校</em
              ></el-col
            >
            <el-col :span="6" :offset="6" class="textAlignR">
              <el-button
                      class="defaultBut mr15"
                      v-if="item.default_school != 1"
                      @click="defa(item.id)"
              >设为默认登录学校</el-button
              >
              <el-button type="text" @click="goto(item.id)">进入></el-button>
            </el-col>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <forgetPassword ref="forgetPassword"></forgetPassword>
    <unbind ref="unbind"></unbind>
  </div>
</template>

<script>
  import $ from "jquery";
  import { getCookie } from '@/assets/js/cookie.js'
  import { changeStr, pinjie, objKeySort } from "@/assets/js/sign";
  import Md5 from 'md5';
  import axios from 'axios'
  import {
    allschoollist,
    bingSchool,
    schoolName,
    changenewpassword,
    bingPhone,
    getstudentinfos,
    setDefault,
    checkSiteid,
    sendsmscode,
    changeaccountname,unBundling
  } from "../assets/api.js";
  import { setCookie,delCookie } from "@/assets/js/cookie.js";
  import forgetPassword from "../../../components/forgetPassword";
  import unbind from './com/unbind.vue'
  export default {
    name: "schoolUsers",
    data() {
      return {
        userdis: false,
        accountName: "",
        account_name: "",
        phone: "",
        datetime: "",
        passwords: "",
        rand: Date.parse(new Date()) / 1000,
        restaurants: [],
        timeout: null,
        activeNames: [],
        personEdit: true,
        person: "ubx_ksksks",
        bindingTel: {
          phone: "",
          captcha: "",
          password: "",
        },
        password: {
          oldpassword: "",
          newpassword: "",
          relpassword: "",
        },
        school: {
          schoolid: "",
          schoolname: "",
          job_num: "",
          name: "",
          default_site: "",
        },
        getyzm: true,
        count: "",
        timer: null,
        schooList: [],
        qqbindFlag: false,
        wxbindFlag: false,
      };
    },
    components: {
      forgetPassword,
      unbind
    },
    mounted() {
      this.loadAll();
      this.schoolname();
      this.getSchool();
    },
    methods: {
      unbindType(type){
        this.$refs.unbind.openInit(type)
      },
      unbingqq() {
        unBundling({ btype: 1 }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.$refs.unbind.dialogVisible = false
            this.getSchool();
          }
        });
      },
      bingqq() {
        if (this.phone) {
          window.open(
                  "/login/v1/qq/authorize",
                  "TencentLogin",
                  "width=800,height=600,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1"
          );
        } else {
          this.$message.error("请先绑定手机号！");
        }
      },
      unbingwx() {
        unBundling({ btype: 2 }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.$refs.unbind.dialogVisible = false
            this.getSchool();
          }
        });
      },
      bingwx() {
        if (this.phone) {
          window.open(
                  "https://open.weixin.qq.com/connect/qrconnect?appid=wx3956eb795735041b&redirect_uri=http%3A%2F%2Fwww.uxiaobang.com%2Flogin/v1%2Fv1%2Fwechat1&response_type=code&scope=snsapi_login,snsapi_userinfo&state=test#wechat_redirect",
                  "WeixinLogin",
                  "width=800,height=600,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1"
          );
        } else {
          this.$message.error("请先绑定手机号！");
        }
      },
      getSchool() {
        getstudentinfos().then((res) => {
          if (res.code == 0) {
            this.phone = res.data.phone;
            this.passwords = res.data.password;
            this.account_name = res.data.account_name;
            this.datetime = res.data.datetime;
            if (res.data.datetime) {
              this.userdis = true;
            }
            console.log(res, this.account_name);
            if (res.data.third.length != 0) {
              this.qqbindFlag = res.data.third.some((item) => item.type == 1);
              this.wxbindFlag = res.data.third.some((item) => item.type == 2);
            } else {
              this.qqbindFlag = false;
              this.wxbindFlag = false;
            }
            this.$addStorageEvent(2, "infos", JSON.stringify(res.data));
          }
        });
      },
      openForget() {
        this.$refs.forgetPassword.openInit();
      },
      onPhone() {
        bingPhone(this.bindingTel).then((res) => {
          this.changeRand()
          if (res.code == 0) {
            this.$message.success(res.message);
          }
        });
      },
      //获取验证码
      changeRand() {
        this.rand = Date.parse(new Date()) / 1000;
      },
      setPass() {
        changenewpassword(this.password).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
          }
        });
      },
      defa(val) {
        let params = {
          default_site: 1,
          schoolid: val,
        };
        setDefault(params).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.schoolname();
          }
        });
      },
      goto(val) {
        delCookie("siteid")
        setCookie("siteid",val)
        const formDatas = {
          siteid: val,
          stime: parseInt(new Date().getTime() / 1000)
        }
        var sign = Md5(changeStr(pinjie(objKeySort(formDatas))))
        const formData = new URLSearchParams()
        formData.append('siteid', val)
        formData.append('stime', formDatas.stime)
        formData.append('sign', sign)
        formDatas.sign = sign
        axios({
          method: 'post',
          url: '/api/login/v1/foreuser/checksiteid',
          data: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Uxb-User-Agent': 'webVersion:v1.0.0'
          }
        }).then((res) => {
          if (res.data.code == 0) {
            $.ajax({
              type: 'get',
              url: '//' + res.data.data.fore_domain + '/login/v1/geturl?dosession=' + getCookie('sid') + '&uxb_token=' + res.data.data.htoken +
                      '&siteid=' +
                      res.data.data.siteid,
              dataType: 'jsonp',
              crossDomain: true,
              xhrFields: {
                withCredentials: true
              },
              success: (res1) => {
                console.log('同步登录成功', res1)
              }
            })
            setTimeout(() => {
              this.getSchool()
              if (res.data.data.identity == 3) {
                //去学习中心
                window.location.href =
                        "https://" + res.data.data.fore_domain + "/student/#/";
              } else {
                window.location.href =
                        "https://" + res.data.data.fore_domain + "/teacher/#/";
              }
            },1000)
          }
        })

      },
      schoolname() {
        schoolName().then((res) => {
          if (res.code == 0) {
            this.schooList = res.data;
          }
        });
      },
      bindschool() {
        if (this.school.default_site) {
          this.school.default_site = 1;
        } else {
          this.school.default_site = 0;
        }
        bingSchool(this.school).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
          }
        });
      },
      getCode() {
        // 倒计时

        let params = {
          phone: this.bindingTel.phone,
          captcha: this.bindingTel.captcha,
        };
        sendsmscode(params).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message);
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.getyzm = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.getyzm = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        });
      },
      editPerson() {
        changeaccountname({ username: this.accountName }).then((res) => {
          if (res.code == 0) {
            this.personEdit = false;
            this.$message.success(res.message);
            this.getSchool();
            this.userdis = true;
            let num = this.activeNames.findIndex((item) => item == 1);
            this.activeNames.splice(num, 1);
          }
        });
      },
      loadAll() {
        allschoollist().then((res) => {
          if (res.code == 0) {
            this.restaurants = res.data;
          }
        });
      },
      handleSelect(item) {
        this.school.schoolid = item.id;
      },
      querySearchAsync(queryString, cb) {
        var restaurants = this.restaurants;
        for (var i = 0; i < restaurants.length; i++) {
          restaurants[i].value = restaurants[i].name;
        }
        var results = queryString
                ? restaurants.filter(this.createStateFilter(queryString))
                : restaurants;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 3000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return state.name.indexOf(queryString) != -1;
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../assets/personalCenter";
</style>
