<template>
	<div class="unbindWrap">
		<el-dialog title="解除绑定" width="500px" :visible.sync="dialogVisible">
			<p>确定要将当前账号与第三方应用账号解绑吗？</p>
			<div class="btnWrap">
				<el-button type="primary" round @click="submit">确定</el-button>
				<el-button round @click="dialogVisible = false">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			dialogVisible: false,
			type: '',
		};
	},
	mounted() {},
	methods: {
		openInit(type) {
			this.dialogVisible = true;
			this.type = type;
		},
		submit() {
			if (this.type == 1) {
				this.$parent.unbingqq();
			} else {
				this.$parent.unbingwx();
			}
		},
	},
};
</script>

<style scoped lang="scss">
.unbindWrap {
	/deep/.el-dialog {
		border-radius: 16px;
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 20px;
			.el-dialog__title {
				font-weight: bold;
				font-size: 16px;
			}
			i {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 30px 0;
		}
	}
	p {
		text-align: center;
		font-size: 16px;
	}
	.btnWrap {
		margin-top: 30px;
		text-align: center;
		.el-button.is-round {
			padding: 12px 45px;
		}
	}
}
</style>
